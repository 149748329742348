require("jquery");
require("jquery-migrate");
require("popper.js");
require("bootstrap");
require("svg-injector");
require("vendor.dzsparallaxer");
require("hs.core");
require("hs.svg-injector");
require("hs.unfold.js");
require("hs.header.js");
$(window).on("load", function() {
  $.HSCore.components.HSSVGIngector.init(".js-svg-injector");
  $.HSCore.components.HSUnfold.init($("[data-unfold-target]"));
  $.HSCore.components.HSHeader.init($("#header"));
  $("#srchMember").on("keyup", function() {
    let txt = $(this).val();
    if (txt.length > 2) {
      $(".res").hide();
      $("#members")
        .find('.res[data-name*="' + txt.toLowerCase() + '"]')
        .show();
    } else {
      $(".res").show();
    }
  });
  $(".notice-alert")
    .delay(2000)
    .fadeOut();
});
